import { Link } from "gatsby"
import {
  CurrencyDollarOutline,
  FastForwardOutline,
  LightBulbOutline,
} from "heroicons-react"
import React from "react"
import { BenefitsGrid } from "../components/BenefitsGrid"
import CallToActionPageBottom from "../components/CallToActionPageBottom"
import Layout from "../components/Layout"
import { ContentHeading, ContentSubHeading } from "../components/styles"

export default ({ pageContext: { team } }) => (
  <>
    <Layout
      title={`Pricelist for ${team.name} | Pricelist`}
      keywords={`Pricelist for ${team.name} features comparison`}
    >
      <div class="relative px-4 sm:px-6 lg:px-8 mt-20">
        <div class=" max-w-prose mx-auto">
          <h1>
            <ContentSubHeading>For {team.name} teams</ContentSubHeading>
            <ContentHeading>{team.heading}</ContentHeading>
            <div className="flex items-center justify-center my-8">
              <Link
                to="/request-demo"
                className="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
              >
                Request a demo
              </Link>
            </div>
          </h1>
        </div>

        <BenefitsGrid benefits={team.benefits} />


        <p class=" text-xl text-gray-500 leading-8 mt-20"></p>
        <p class="mt-8 text-xl text-gray-500 leading-8">{team.snippet}</p>

        <CallToActionPageBottom />

        <div class="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
          <p>
            <Link to="/teams">Go Back</Link>
          </p>
        </div>
      </div>
    </Layout>
  </>
)
