import React from "react"
import { Fade } from "react-reveal"
import {
  CurrencyDollarOutline,
  FastForwardOutline,
  LightBulbOutline,
} from "heroicons-react"
import { Link } from "gatsby"

export const BenefitsGrid = ({ benefits }) => {
  const icons = [
    <FastForwardOutline />,
    <LightBulbOutline />,
    <CurrencyDollarOutline />,
  ]

  const colors = ["blue", "yellow", "green"]

  return (
    <Fade cascade>
      <div className="grid gap-8 grid-cols-1 mt-20 mx-auto max-w-5xl text-center text-gray-800 font-light sm:grid-cols-3">
        {!benefits && <p>No benefits</p>}
        {benefits &&
          benefits.map(({ heading, description }, idx) => (
            <div className="flex flex-col items-center" key={idx}>
              <div className={`text-${colors[idx % 3]}-400 w-10 h-auto`}>
                {icons[idx % 3]}
              </div>
              <h3 className="pb-2 pt-3 text-xl font-normal">{heading}</h3>
              <p className="text-gray-500 leading-relaxed">{description}</p>
            </div>
          ))}

        <Link
          to="/solutions/intelligent-pricing-automation"
          className="grid-cols-1 pt-4 text-center text-indigo-700 hover:no-underline underline sm:col-span-3"
        >
          See all product features
        </Link>
      </div>
    </Fade>
  )
}
